import * as React from "react";
import * as styles from "./style.module.scss";
import track from "utils/analytic";
import Link from "components/Link";

function SocialLinks({ socialLinks }) {
  return (
    <div className={styles.socialLinks}>
      {socialLinks.map((props) => (
        <SocialLink
          key={props.name}
          onClick={() => track({ action: `${props.name} social link clicked` })}
          {...props}
        />
      ))}
    </div>
  );
}

function SocialLink({ url, src, name, onClick }) {
  return (
    <Link external to={url} target="__blank" onClick={onClick}>
      <img
        className={styles.socialIcon}
        src={src}
        alt={`${name} social icon`}
      />
    </Link>
  );
}

export default SocialLinks;
