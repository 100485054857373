import React, { useState } from "react";
import * as styles from "./styles.module.scss";
import Link from "components/Link";
import { navData } from "../../../config";
import useWindowWidth from "components/hooks/useWindowWidth";
import CohesionLogo from "images/co-hesion-dark-mode.png";
import UnorderedList, { ListItem } from "components/UnorderedList";
import track from "utils/analytic";
import CaretDownIcon from "svg/caret-down.svg";
import IconButtonContainer from "components/IconButtonContainer";
import { classnames, scrollToTop } from "utils";
import { textRight } from "styles/_utils.module.scss";

const links = navData.links;

function NavBar() {
  const isTabletWidth = useWindowWidth() > 1024;
  const [isSectionExpanded, setIsSectionExpanded] = useState(false);

  function onMobileNavLinkItemClicked() {
    const checkbox = document.getElementById("hamburgerCheckbox");
    const mobileNav = document.querySelector(`.${styles.mobileNav}`);

    if (checkbox.checked) {
      mobileNav?.classList.toggle(`${styles.showMobileNav}`);
      checkbox.checked = !checkbox.checked;
    } else {
      return;
    }
  }

  return (
    <>
      <nav>
        <Logo />
        {isTabletWidth ? (
          <UnorderedList plain className={styles.navLinks}>
            <div>
              {links.map((link) => (
                <li key={link.text} className={styles.navLinkItem}>
                  <Link
                    external
                    to={link.to}
                    activeClassName={styles.activeLinkClassName}
                    // className={styles.navLinkText}
                    onClick={() => {
                      scrollToTop();
                      track({
                        action: `Navigation link ${link.text} clicked`
                      });
                    }}
                  >
                    {link.text}
                  </Link>
                  {link.children && (
                    <UnorderedList plain className={styles.subNavItem}>
                      {link?.children?.map((subItem) => (
                        <React.Fragment key={subItem.text}>
                          <li>
                            <Link
                              to={subItem.to}
                              onClick={() => {
                                track({
                                  action: `Navigation link ${subItem.text} clicked`
                                });
                              }}
                            >
                              <small>{subItem.text}</small>
                            </Link>
                          </li>
                        </React.Fragment>
                      ))}
                    </UnorderedList>
                  )}
                </li>
              ))}
            </div>
          </UnorderedList>
        ) : (
          <ul className={styles.mobileNav}>
            {links.map((link) => (
              <React.Fragment key={link.text}>
                <li
                  key={link.text}
                  className={classnames({
                    [styles.borderBottomNone]:
                      isSectionExpanded &&
                      link.text.toLowerCase() === "thinking"
                  })}
                  onClick={onMobileNavLinkItemClicked}
                >
                  <Link to={link.to}>{link.text}</Link>
                  {link.children ? (
                    <IconButtonContainer
                      className={styles.mobileNavLinkSubItemExpandButton}
                      onClick={(e) => {
                        e.stopPropagation();
                        document
                          .querySelector(
                            `.${styles.mobileNavLinkSubItemExpandButton}`
                          )
                          .classList.toggle(styles.rotateButton);
                        setIsSectionExpanded((curr) => !curr);
                      }}
                    >
                      <CaretDownIcon height={24} width={24} />
                    </IconButtonContainer>
                  ) : null}
                </li>
                {isSectionExpanded &&
                  link.children?.map((linkItem) => (
                    <UnorderedList key={linkItem.text} plain>
                      <ListItem
                        style={{ borderTop: "none" }}
                        className={classnames({
                          [textRight]: isSectionExpanded
                        })}
                      >
                        <Link
                          to={linkItem.to}
                          onClick={onMobileNavLinkItemClicked}
                        >
                          {linkItem.text}
                        </Link>
                      </ListItem>
                    </UnorderedList>
                  ))}
              </React.Fragment>
            ))}
          </ul>
        )}
        <HamburgerMenu onClick={onMenuClick} />
      </nav>
    </>
  );
}

function Logo() {
  function onClick() {
    const checkbox = document.getElementById("hamburgerCheckbox");
    const mobileNav = document.querySelector(`.${styles.mobileNav}`);

    if (mobileNav && checkbox.checked) {
      mobileNav?.classList.toggle(`${styles.showMobileNav}`);
      checkbox.checked = false;
    }
  }

  return (
    <Link className={styles.logo} to="/" onClick={onClick}>
      <img src={CohesionLogo} alt="co-hesion logo" />
    </Link>
  );
}

function HamburgerMenu(props) {
  return (
    <div className={styles.hamburgerMenu} {...props}>
      <input
        className={styles.checkbox}
        type="checkbox"
        name="hamburgerCheckbox"
        id="hamburgerCheckbox"
      />
      <div className={styles.lines}>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
}

function onMenuClick() {
  const mobileNav = document.querySelector(`.${styles.mobileNav}`);

  if (mobileNav) {
    mobileNav?.classList.toggle(`${styles.showMobileNav}`);
  }
}

export default NavBar;
