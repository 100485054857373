import React, { useState, useEffect } from "react";
import * as styles from "./NewArticleBanner.module.scss";
import { graphql, useStaticQuery, Link } from "gatsby";
import { getCookie, setCookie } from "utils";
import track from "utils/analytic";
export const BANNER_VIEWED_COOKIE = "banner_viewed";

const NewArticleBanner = () => {
  const [isClicked, setIsClicked] = useState(false);
  const data = useStaticQuery(graphql`
    query {
      allWpPost {
        edges {
          node {
            slug
            title
          }
        }
      }
    }
  `);

  useEffect(() => {
    if (isClicked) {
      if (!getCookie(BANNER_VIEWED_COOKIE)) {
        setCookie(BANNER_VIEWED_COOKIE, 1);
      }
    }
  }, [isClicked]);

  const node = data.allWpPost?.edges?.[0]?.node;
  const url = node.slug;
  const title = node.title;
  const uri = `/insights/${url}`;

  if (!title || !url) return null;
  return (
    <div className={styles.container} id="new-article-banner">
      <strong>Read Latest Article:</strong>
      <Link
        to={uri}
        rel="noreferrer"
        onClick={() => {
          track({ action: "New article banner clicked" });
          setIsClicked(true);
        }}
      >
        &nbsp;{title} &#8250;
      </Link>
    </div>
  );
};

export default NewArticleBanner;
